import React, { useRef } from "react";
import "../css/HorizontalScroll.css";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

const HorizontalScroll = ({ children, navigateButtons = true, scrollAmount = 467 }) => {
    const containerRef = useRef(null);

    const handleScrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
        }
    };

    const handleScrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
    };

    return (
        <div className="horizontal-scroll">
            {navigateButtons && <button className="scroll-button left dark:bg-black dark:hover:bg-neutral-950" onClick={handleScrollLeft}>
                <LeftOutlined className={"tblue dark:tred font-bold"}/>
            </button>}
            <div className="scroll-container px-2" ref={containerRef}>
                {children}
            </div>
            {navigateButtons && <button className="scroll-button right dark:bg-black dark:hover:bg-neutral-950" onClick={handleScrollRight}>
                <RightOutlined className={"tblue dark:tred font-bold"}/>
            </button>}
        </div>
    );
};

export default HorizontalScroll;

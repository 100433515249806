import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {useEffect, useState} from "react";
import Transition from "../components/Transition";
import { motion } from "framer-motion";

export default function Dispatcher({ backendApiUrl, content }) {
    const [isTransitionVisible, setIsTransitionVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTransitionVisible(false);
        }, 800);

        return () => clearTimeout(timer);
    }, []);


    useEffect(() => {
        const updateMinHeight = () => {
            const height = window.innerHeight - 68;
            document.documentElement.style.setProperty('--min-height', `${height}px`);
        };
        updateMinHeight();
        window.addEventListener('resize', updateMinHeight);
        return () => window.removeEventListener('resize', updateMinHeight);
    }, []);

    return (
        <div className={"dark:bg-darkModeBackgroundColor dark:text-white"}>
            {isTransitionVisible ? (
                <Transition/>
            ) : (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    <Navbar backendApiUrl={backendApiUrl}/>
                    {content}
                    <Footer backendApiUrl={backendApiUrl}/>
                </motion.div>
            )}
        </div>
    );
};
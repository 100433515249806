import React, {useEffect, useState} from 'react';
import '../App.css';
import {Input, Skeleton, Divider, Button} from 'antd'
import HorizontalScroll from "../components/HorizontalScroll";
import axios from "axios";
import MovieCard from "../components/MovieCard";
import SerieCard from "../components/SerieCard";
import {useNavigate} from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import {useWindowDimensions} from "../contexts/WindowDimensionsContext";
import { useTheme } from '../contexts/ThemeContext';

const { Search } = Input;

const Home = ({ backendApiUrl }) => {
    const { isDarkMode } = useTheme()
    const [groupedMovies, setGroupedMovies] = useState({});
    const [groupedSeries, setGroupedSeries] = useState({});
    const windowDimensions = useWindowDimensions();
    const navigate = useNavigate()

    const movieSearch = async (search) => {
        navigate("/movies?search=" + search)
    };

    useEffect(() => {
        const syncMovies = async () => {
            try {
                await axios.get(`${backendApiUrl}/movies/popular/sync`);
                await axios.get(`${backendApiUrl}/movies/top-rated/sync`);
                await axios.get(`${backendApiUrl}/movies/upcoming/sync`);
            } catch (err) {
                console.error('Error syncing movies:', err);
            }
        }

        const syncSeries = async () => {
            try {
                await axios.get(`${backendApiUrl}/series/popular/sync`);
                await axios.get(`${backendApiUrl}/series/top-rated/sync`);
            } catch (err) {
                console.error('Error syncing series:', err);
            }
        }

        const fetchMovies = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/movies`);
                const fetchedMovies = response.data;

                const grouped = fetchedMovies.reduce((acc, movie) => {
                    movie.type.forEach(type => {
                        if (!acc[type]) {
                            acc[type] = [];
                        }
                        acc[type].push(movie);
                    });
                    return acc;
                }, {});

                setGroupedMovies(grouped);
            } catch (err) {
                console.error('Error fetching movies:', err);
            }
        };

        const fetchSeries = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/series`);
                const fetchedSeries = response.data;

                const grouped = fetchedSeries.reduce((acc, serie) => {
                    serie.type.forEach(type => {
                        if (!acc[type]) {
                            acc[type] = [];
                        }
                        acc[type].push(serie);
                    });
                    return acc;
                }, {});

                setGroupedSeries(grouped);
            } catch (err) {
                console.error('Error fetching series:', err);
            }
        };

        fetchMovies();
        fetchSeries();
        syncMovies();
        syncSeries()
    }, []);

    return (
        <div className="app">
            <div className={"max-w-5xl mx-auto mb-8"}>
                <div className={"pt-20 pb-10"}>
                    <p className={`text-left ${windowDimensions.wWCheck(700) ? "text-4xl" : "text-2xl"} font-bold tblue dark:tred`}>Welcome to Fritzlix,</p>
                    <p className={`text-left ${windowDimensions.wWCheck(700) ? "text-2xl" : "text-lg"} font-bold mt-2`}>Information about millions of movies and series...</p>
                </div>
                <Search
                    placeholder="Search for a serie or a movie title"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={movieSearch}
                />
            </div>
            <Divider style={{borderColor: isDarkMode ? "var(--my-red)" : "var(--my-blue)", color: isDarkMode ? "var(--my-red)" : "var(--my-blue)", fontWeight: "bold", fontSize: windowDimensions.wWCheck(700) ? "30px" : "18px", marginTop: "80px", marginBottom: "30px" }}>MOVIES</Divider>
            {["popular", "upcoming", "top_rated"].map((type) => (
                <div key={type} className={"mt-6"}>
                    <p className={`text-left ${windowDimensions.wWCheck(700) ? "text-2xl" : "text-lg"} font-bold mb-3 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>{type.replace('_', ' ').toUpperCase()}</p>
                    <HorizontalScroll navigateButtons={windowDimensions.wWCheck(1000)}>
                        {groupedMovies[type]?.length > 0
                            ? groupedMovies[type].map(movie => (
                                <MovieCard key={movie.id} movie={movie} />
                            ))
                            : Array(10).fill(null).map((_, skeletonIndex) => (
                                <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            width: 250,
                                            height: 375,
                                        }}
                                        className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                    />
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "22px",
                                            width: 250,
                                            height: 90,
                                        }}
                                        className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[140px]"}`}
                                    />
                                </div>
                            ))
                        }
                    </HorizontalScroll>
                </div>
            ))}
            <div className={"flex justify-center"}>
                <Button type={"primary"} className={`${windowDimensions.wWCheck(700) ? "px-32" : "w-full"} mt-10`} size={"large"} onClick={() => {scroll.scrollToTop({duration: 0}); navigate("/movies")}}>Advanced movie search</Button>
            </div>
            <Divider style={{borderColor: isDarkMode ? "var(--my-red)" : "var(--my-blue)", color: isDarkMode ? "var(--my-red)" : "var(--my-blue", fontWeight: "bold", fontSize: windowDimensions.wWCheck(700) ? "30px" : "18px", marginTop: "130px", marginBottom: "30px" }}>SERIES</Divider>
            {["popular", "top_rated"].map((type) => (
                <div key={type} className={"mt-6"}>
                    <p className={`text-left ${windowDimensions.wWCheck(700) ? "text-2xl" : "text-lg"} font-bold mb-3 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>{type.replace('_', ' ').toUpperCase()}</p>
                    <HorizontalScroll navigateButtons={windowDimensions.wWCheck(1000)}>
                        {groupedSeries[type]?.length > 0
                            ? groupedSeries[type].map(serie => (
                                <SerieCard key={serie.id} serie={serie} />
                            ))
                            : Array(10).fill(null).map((_, skeletonIndex) => (
                                <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            width: 250,
                                            height: 375,
                                        }}
                                        className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                    />
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "22px",
                                            width: 250,
                                            height: 90,
                                        }}
                                        className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[140px]"}`}
                                    />
                                </div>
                            ))
                        }
                    </HorizontalScroll>
                </div>
            ))}
            <div className={"flex justify-center"}>
                <Button type={"primary"} className={`${windowDimensions.wWCheck(700) ? "px-32" : "w-full"} mt-10`} size={"large"} onClick={() => {scroll.scrollToTop({duration: 0}); navigate("/series")}}>Advanced series search</Button>
            </div>
        </div>
    );
};

export default Home;


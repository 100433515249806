import MyRoutes from "./MyRoutes";
import {BrowserRouter} from "react-router-dom";
import './App.css'
import WindowDimensionsProvider from "./contexts/WindowDimensionsContext";
import { ConfigProvider, theme } from 'antd';
import { ThemeContext, ThemeProvider } from "./contexts/ThemeContext"
import {useContext} from "react";

export function AppBis() {
    const { isDarkMode } = useContext(ThemeContext);

    return (
        <ConfigProvider
            theme={{
                algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
                token: {
                    colorPrimary: isDarkMode ? '#b61e26' : '#1677FF',
                    colorLink: isDarkMode ? "#b61e26" : "#1677FF",
                    colorLinkHover: isDarkMode ? "#a41b22" : "#40a9ff",
                    colorBgContainer: isDarkMode ? '#000000' : '#ffffff',
                    colorBgElevated: isDarkMode ? '#000000' : '#ffffff',
                    colorText: isDarkMode ? '#ffffff' : '#000000',
                },
            }}
        >
            <BrowserRouter>
                <WindowDimensionsProvider>
                    <MyRoutes/>
                </WindowDimensionsProvider>
            </BrowserRouter>
        </ConfigProvider>
    );
}

export function App() {
    return (
        <ThemeProvider>
            <AppBis />
        </ThemeProvider>
    );
}

export default App;
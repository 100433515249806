import "../css/Card.css"
import {Progress, Badge} from "antd";
import {useNavigate} from "react-router-dom";
import {animateScroll as scroll} from "react-scroll";
import React from "react";
import {useWindowDimensions} from "../contexts/WindowDimensionsContext";

const MovieCard = ({ movie, card = false, center = true, noMax = false }) => {
    const navigate = useNavigate()
    const windowDimensions = useWindowDimensions()

    const formatReleaseDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <div className={`${card ? "simple-card-small" : "card-small"} dark:bg-darkModeBackgroundColor dark:text-white dark:border-0 dark:shadow-none max-h-[600px] ${!windowDimensions.wWCheck(700) && noMax === false && "max-w-[140px]"} ${center ? "mx-auto" : ""}`} onClick={() => {scroll.scrollToTop({duration: 0}); navigate("/movie?id=" + movie.id)}}>
            <Badge.Ribbon text={"For Adult"} color="pink" placement={"start"} className={`mt-1 ${movie.adult ? "" : "hidden"}`}>
                <Badge.Ribbon text="Available" color="green" className={`mt-1 ${movie.streaming ? "" : "hidden"}`}>
                    <img
                        src={
                            movie.poster_path
                                ? `https://image.tmdb.org/t/p/w500${movie.poster_path}`
                                : "https://via.placeholder.com/130x195?text=No+Image"
                        }
                        alt={movie.title}
                        className={`${card ? "poster-simple-small" : "poster-small"}`}
                    />
                </Badge.Ribbon>
            </Badge.Ribbon>
            <div className={"flex items-center mt-3"}>
                <Progress percent={movie.vote_average * 10 >= 100 ? 99 : (movie.vote_average * 10).toFixed(2)} strokeColor={{'0%': '#FF4D4F', '40%': '#FFB01B', '60%': '#53C31B'}} showInfo={false} className={"pl-2"}/>
                <p className={"dark:text-gray-300 font-light text-sm my-auto pl-2 pr-2"}>{(movie.vote_average * 10).toFixed(0)}%</p>
            </div>
            <div className="details-small">
                <p className={"font-bold mt-3 px-1"}>{movie.title}</p>
                <span className={"text-sm px-1"}>{formatReleaseDate(movie.release_date)}</span>
            </div>
        </div>
    );
};

export default MovieCard;

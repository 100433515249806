import React from 'react';
import { motion } from "framer-motion";
import {useWindowDimensions} from "../contexts/WindowDimensionsContext";
import { useTheme } from '../contexts/ThemeContext';

export default function Transition() {
    const { isDarkMode } = useTheme()
    const windowDimensions = useWindowDimensions()

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0, transition: { duration: 0.3, delay: 0.2 } }}
        >
            <div className={"h-lvh flex-center"}>
                <motion.img
                    className={`${windowDimensions.wWCheck(700) ? "h-72" : "h-40"} w-auto`}
                    src={isDarkMode ? "/logo-dark.png" : "/logo.png"}
                    alt="Your Company"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.3 }}
                    exit={{ opacity: 0, transition: { duration: 0.3, delay: 0.2 } }}
                />
            </div>
        </motion.div>
    );
}
import { Route, Routes } from "react-router-dom";
import Dispatcher from "./pages/Dispatcher";
import AdvancedMovieFilter from "./pages/AdvancedMovieFilter";
import Home from "./pages/Home";
import MovieDetails from "./pages/MovieDetails";
import AdvancedSerieFilter from "./pages/AdvancedSerieFilter";
import SerieDetails from "./pages/SerieDetails";
import Available from "./pages/Available";
import {useEffect} from "react";
import axios from "axios";

export function MyRoutes() {
    const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL || "https://test-frederic.fun/api";

    useEffect(() => {
        const createPrivateToggle = async () => {
            try {
                const response = await axios.get(`${BACKEND_API_URL}/private/create`);
            } catch (err) {
                console.error('Error creating private toggle:', err);
            }
        };

        createPrivateToggle();
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Dispatcher backendApiUrl={BACKEND_API_URL} content={<Home backendApiUrl={BACKEND_API_URL}/>}/>}/>
            <Route path="/movies" element={<Dispatcher backendApiUrl={BACKEND_API_URL} content={<AdvancedMovieFilter backendApiUrl={BACKEND_API_URL}/>}/>}/>
            <Route path="/series" element={<Dispatcher backendApiUrl={BACKEND_API_URL} content={<AdvancedSerieFilter backendApiUrl={BACKEND_API_URL}/>}/>}/>
            <Route path="/movie" element={<Dispatcher backendApiUrl={BACKEND_API_URL} content={<MovieDetails backendApiUrl={BACKEND_API_URL}/>}/>}/>
            <Route path="/serie" element={<Dispatcher backendApiUrl={BACKEND_API_URL} content={<SerieDetails backendApiUrl={BACKEND_API_URL}/>}/>}/>
            <Route path="/available" element={<Dispatcher backendApiUrl={BACKEND_API_URL} content={<Available backendApiUrl={BACKEND_API_URL}/>}/>}/>
        </Routes>
    );
}

export default MyRoutes;

import React, { useEffect, useRef } from "react";
import Hls from "hls.js";

const VideoPlayer = ({ videoUrl }) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (Hls.isSupported() && videoUrl.endsWith(".m3u8")) {
            const hls = new Hls();
            hls.loadSource(videoUrl);
            hls.attachMedia(videoRef.current);

            return () => {
                hls.destroy();
            };
        } else if (videoRef.current && videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
            videoRef.current.src = videoUrl;
        }
    }, [videoUrl]);

    return <video ref={videoRef} controls className={"rounded-xl"} style={{ width: "100%", height: "100%" }} />;
};

export default VideoPlayer;

import React, {useState, useEffect, useMemo, useRef} from 'react';
import axios from 'axios';
import {CaretRightOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Divider, Input, Slider, Switch, DatePicker, Select, Spin, Skeleton, Pagination, Collapse} from 'antd';
import MovieCard from "../components/MovieCard";
import Search from "antd/es/input/Search";
import {useLocation, useNavigate} from "react-router-dom";
import debounce from 'lodash/debounce';
import {useWindowDimensions} from "../contexts/WindowDimensionsContext";
import { animateScroll as scroll, scroller } from 'react-scroll';
import { useTheme } from '../contexts/ThemeContext';

const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const AdvancedMovieFilter = ({ backendApiUrl }) => {
    const { isDarkMode } = useTheme()
    const [moviesGenres, setMoviesGenres] = useState([]);
    const [movies, setMovies] = useState([]);
    const fetchRef = useRef(0);
    const [order, setOrder] = useState('title.desc');
    const [yearsRange, setYearsRange] = useState(false);
    const [yearSelect, setYearSelect] = useState(null);
    const [yearsRangeSelect, setYearsRangeSelect] = useState(null);
    const [likedGenres, setLikedGenres] = useState([]);
    const [score, setScore] = useState([0, 10]);
    const [adults, setAdults] = useState(false);
    const [keywords, setKeywords] = useState(null);
    const query = useQuery();
    const search = query.get("search");
    const navigate = useNavigate()
    const windowDimensions = useWindowDimensions()
    const [page, setPage] = useState(0)
    const [elementsStep, setElementsStep] = useState(10)
    const [activeKey, setActiveKey] = useState(windowDimensions.wWCheck(700) ? ['1'] : []);

    useEffect(() => {
        const fetchMoviesGenres = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/movies/genres`);
                setMoviesGenres(response.data);
            } catch (err) {
                console.error('Error fetching movies genres:', err);
            }
        };

        fetchMoviesGenres();
        if (search)
            movieSearch(search)
        else {
            fetchMovies();
        }
    }, []);

    useEffect(() => {
        if (page * elementsStep >= movies.length) {
            setPage(0)
        }
    }, [elementsStep, movies.length]);

    const fetchMovies = async () => {
        try {
            const response = await axios.get(`${backendApiUrl}/movies`);
            setMovies(response.data);
        } catch (err) {
            console.error('Error fetching movies:', err);
        }
    };

    function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
        const [fetching, setFetching] = useState(false);
        const [options, setOptions] = useState([]);
        const fetchRef = useRef(0);
        const debounceFetcher = useMemo(() => {
            const loadOptions = (value) => {
                fetchRef.current += 1;
                const fetchId = fetchRef.current;
                setOptions([]);
                setFetching(true);
                fetchOptions(value).then((newOptions) => {
                    if (fetchId !== fetchRef.current) {
                        return;
                    }
                    setOptions(newOptions);
                    setFetching(false);
                });
            };
            return debounce(loadOptions, debounceTimeout);
        }, [fetchOptions, debounceTimeout]);
        return (
            <Select
                labelInValue
                filterOption={false}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={options}
            />
        );
    }

    const handleFilter = async () => {
        const year = yearSelect ? yearSelect?.$y : null;
        const primaryReleaseDateGte = yearsRangeSelect?.[0]?.$d.toISOString().split('T')[0];
        const primaryReleaseDateLte = yearsRangeSelect?.[1]?.$d.toISOString().split('T')[0];
        const withGenres = likedGenres.join('|');
        const voteAverageGte = score[0];
        const voteAverageLte = score[1];
        const includeAdult = adults ? 'true' : 'false';
        const withKeywords = keywords?.length ? keywords.map(keyword => keyword.value).join('|') : null;

        const queryParams = [];

        setMovies([])
        setPage(0)
        !windowDimensions.wWCheck(1050) && setActiveKey([]);

        queryParams.push(`sort_by=${order}`);
        if (yearsRange) {
            queryParams.push(
                `primary_release_date.gte=${primaryReleaseDateGte}`,
                `primary_release_date.lte=${primaryReleaseDateLte}`
            );
        } else if (year !== null) {
            queryParams.push(`year=${year}`);
        }
        if (withGenres)
            queryParams.push(`with_genres=${withGenres}`);
        if (voteAverageGte !== 0 || voteAverageLte !== 10) {
            queryParams.push(`vote_average.gte=${voteAverageGte}`, `vote_average.lte=${voteAverageLte}`);
        }
        if (adults)
            queryParams.push(`include_adult=${includeAdult}`);
        if (withKeywords)
            queryParams.push(`with_keywords=${withKeywords}`);

        const queryString = queryParams.join('&');
        const encodedQuery = encodeURI(queryString);

        try {
            const response = await axios.get(`${backendApiUrl}/movies/search/filter/${encodedQuery}`);
            setMovies(response.data);
            !windowDimensions.wWCheck(1050) && scroll.scrollToTop()
        } catch (err) {
            console.error('Error searching for movies:', err);
            setMovies([]);
        }
    };

    const movieSearch = async (internalSearch) => {
        setMovies([])
        setPage(0)

        if (internalSearch === "") {
            fetchMovies();
            return
        }
        !windowDimensions.wWCheck(1050) && setActiveKey([]);
        try {
            const response = await axios.get(`${backendApiUrl}/movies/search/${internalSearch !== "" ? internalSearch : search}`);
            setMovies(response.data)
            setPage(0)
        } catch (err) {
            console.error('Error searching for movies:', err);
            setMovies([])
        }
    };

    const handleReset = async () => {
        setOrder('title.desc');
        setYearsRange(false);
        setYearSelect(null);
        setYearsRangeSelect(null);
        setLikedGenres([]);
        setScore([0, 10]);
        setAdults(false);
        setKeywords(null);
        fetchMovies()
    };

    const handleLikeGenre = (genre) => {
        if (likedGenres.includes(genre)) {
            setLikedGenres((prev) => prev.filter((g) => g !== genre));
            return;
        } else
            setLikedGenres((prev) => [...prev, genre]);
    };

    async function fetchKeywords(search) {
        return fetch(`${backendApiUrl}/keyword/${search}`)
            .then((response) => response.json())
            .then((body) =>
                body.results.map((keyword) => ({
                    value: keyword.id,
                    label: keyword.name,
                })),
            );
    }

    return (
        <div className={"app"}>
            <Divider id={"movies"} style={{borderColor: isDarkMode ? "var(--my-red)" : "var(--my-blue)", color: isDarkMode ? "var(--my-red)" : "var(--my-blue", fontWeight: "bold", fontSize: windowDimensions.wWCheck(700) ? "30px" : "18px", marginTop: "70px", marginBottom: "50px", whiteSpace: "wrap"}}>FILTER SEARCH FOR MOVIES</Divider>
            <div className={"max-w-5xl mx-auto mb-20"}>
                <Search
                    placeholder="Search for a movie title"
                    allowClear
                    enterButton="Search"
                    size="large"
                    onSearch={movieSearch}
                />
            </div>
            <div className={`flex ${windowDimensions.wWCheck(1050) ? "" : "flex-col"} gap-16`}>
                <div className={`${windowDimensions.wWCheck(1050) ? "w-[490px]" : "flex-col max-w-[700px]"} flex flex-col gap-3`}>
                    <Collapse
                        bordered={false}
                        activeKey={activeKey}
                        onChange={(key) => setActiveKey(key)}
                        expandIcon={({ isActive }) => (
                            <CaretRightOutlined className={`${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"} my-auto h-8`} rotate={isActive ? 90 : 0} />
                        )}
                        className="bg-transparent rounded"
                    >
                        <Panel
                            key="1"
                            className={`bg-white dark:bg-darkModeBackgroundColor dark:text-white rounded-lg border-2 border-gray-200 dark:border-0`}
                            header={<p className={`font-bold text-2xl`}>Filter:</p>}
                        >
                            <p className="text-left font-bold text-lg mb-3">Order:</p>
                            <Select
                                defaultValue="title.desc"
                                className="w-full"
                                value={order}
                                onChange={(value) => setOrder(value)}
                                options={[
                                    { value: "title.desc", label: "Title (A to Z)" },
                                    { value: "title.asc", label: "Title (Z to A)" },
                                    { value: "primary_release_date.desc", label: "Release Date +/-" },
                                    { value: "primary_release_date.asc", label: "Release Date -/+" },
                                    { value: "vote_average.desc", label: "Note Score +/-" },
                                    { value: "vote_average.asc", label: "Note Score -/+" },
                                ]}
                            />
                            <Divider />
                            <p className="text-left font-bold text-lg mb-3">Release date:</p>
                            <div className="flex justify-between mb-2">
                                <p>Precise time range?</p>
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    checked={yearsRange}
                                    onChange={(e) => setYearsRange(e)}
                                />
                            </div>
                            {!yearsRange && (
                                <DatePicker
                                    className="w-full"
                                    picker="year"
                                    onChange={(e) => setYearSelect(e)}
                                    value={yearSelect}
                                />
                            )}
                            {yearsRange && (
                                <RangePicker
                                    format="DD/MM/YYYY"
                                    className="w-full"
                                    onChange={(e) => setYearsRangeSelect(e)}
                                    value={yearsRangeSelect}
                                />
                            )}
                            <Divider />
                            <p className="text-left font-bold text-lg mb-3">With genres:</p>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "10px",
                                    flexWrap: "wrap",
                                    marginBottom: "15px",
                                }}
                            >
                                {moviesGenres.map((genre) => (
                                    <span
                                        key={genre.id}
                                        className={`rounded px-2 py-1 cursor-pointer ${
                                            likedGenres.includes(genre.id)
                                                ? isDarkMode ? "bred text-white" : "bblue text-white"
                                                : "bg-gray-200 dark:bg-black"
                                        }`}
                                        onClick={() => handleLikeGenre(genre.id)}
                                    >
                            {genre.name}
                        </span>
                                ))}
                            </div>
                            <Divider />
                            <p className="text-left font-bold text-lg mb-3">Evaluation score:</p>
                            <Slider
                                marks={{ 0: "0", 5: "5", 10: "10" }}
                                range
                                value={score}
                                max={10}
                                onChange={(e) => setScore(e)}
                            />
                            <Divider />
                            <p className="text-left font-bold text-lg mb-2">For Adults:</p>
                            <div className="flex justify-between">
                                <p>Content is considered for adults?</p>
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    checked={adults}
                                    onChange={(e) => setAdults(e)}
                                />
                            </div>
                            <Divider />
                            <p className="text-left font-bold text-lg mb-3">Keywords:</p>
                            <DebounceSelect
                                mode="multiple"
                                className="w-full"
                                value={keywords}
                                placeholder="Keywords tags"
                                fetchOptions={fetchKeywords}
                                onChange={(newValue) => setKeywords(newValue)}
                            />
                            <div className="mt-8 space-y-2">
                                <Button
                                    size="large"
                                    type="primary"
                                    icon={<SearchOutlined />}
                                    onClick={handleFilter}
                                    block
                                >
                                    Search
                                </Button>
                                <Button size="large" onClick={handleReset} block>
                                    Reset filters
                                </Button>
                                <Button
                                    size="large"
                                    type="link"
                                    className="w-full text-left dark:text-red-500"
                                    onClick={() => {
                                        navigate("/series");
                                    }}
                                >
                                    Filter for series
                                </Button>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
                <div className={"w-full"}>
                    <div id={"list"} className="list-small">
                        {movies?.length > 0
                            ? movies.slice(page * elementsStep, (page + 1) * elementsStep).map((movie) => (
                                <MovieCard key={movie.id} movie={movie} card={true} noMax={true}/>
                            ))
                            : Array(10).fill(null).map((_, skeletonIndex) => (
                                <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            width: 250,
                                            height: 375,
                                        }}
                                    />
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "22px",
                                            width: 250,
                                            height: 90,
                                        }}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <p className={"text-center mt-14 font-bold"}>{movies.length} movies were found</p>
                    <Pagination className={"mt-6"} current={page + 1} showQuickJumper total={movies.length} align="center" onChange={(pageNumber, pageSize) => {setPage(pageNumber - 1); setElementsStep(pageSize); scroller.scrollTo("list", {duration: 800, smooth: true, offset: -40})}}/>
                </div>
            </div>
        </div>
    );
};

export default AdvancedMovieFilter;


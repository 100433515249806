import React, {useState} from "react";
import {Button, Input, Popconfirm, Progress, Spin} from "antd";
import {CloseOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter} from "@nextui-org/react";
import axios from "axios";
import {Dropzone} from "./dropzone";
import "../css/Modal.css"
const VideoUpload = ({ backendApiUrl, onClose, setVideoUrl, isOpen, uploadElementId, type }) => {
    const [file, setFile] = useState(null);
    const [elementId, setElementId] = useState(null);
    const [newElement, setNewElement] = useState({_id: "", title: "", video: ""});
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleElementSubmit = async (event) => {
        event?.preventDefault();

        if (!file) {
            alert("Please select a file.");
            return;
        }

        const formData = new FormData();
        formData.append("video", file);

        try {
            const config = {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const total = progressEvent.total || 1;
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
                    setUploadProgress(percentCompleted);
                },
            };

            if (elementId) {
                const response = await axios.put(`${backendApiUrl}/upload/modify/${type}/${uploadElementId}`, formData, config);
                setVideoUrl(`${backendApiUrl}${response.data.url}`);
            } else {
                const response = await axios.post(`${backendApiUrl}/upload/${type}/${uploadElementId}`, formData, config);
                setVideoUrl(`${backendApiUrl}${response.data.url}`);
            }
            onClose()
            resetForm();
        } catch (error) {
            console.error("Error uploading video:", error);
            alert("Failed to upload video.");
        }
    };

    const handleElementDelete = async () => {

    };

    const resetForm = () => {
        setElementId(null);
        setNewElement(null);
        setFile(null);
    };

    return (
        <div className="modal-backdrop" onClick={() => {onClose();resetForm();}}>
            <Modal style={{ padding: "40px", width: "100%" }} hideCloseButton={true} isOpen={isOpen} className={"modal flex flex-col h-max overflow-y-auto"} onClick={(e) => e.stopPropagation()}>
                <ModalContent className={"rounded-lg shadow-sm bg-white max-w-96 overflow-y-auto"}>
                    <ModalHeader className={"font-bold text-2xl mt-3 mb-2 mx-auto"}>
                        {elementId ? "MODIFY THE VIDEO" : "UPLOAD THE VIDEO"}
                    </ModalHeader>
                    <form onSubmit={handleElementSubmit}>
                        <ModalBody>
                            <div className="w-full mb-4 mt-2">
                                <span className="block text-gray-500 font-medium mb-1">Video</span>
                                <Dropzone onChange={(file) => setFile(file)} initialFile={file} fileType={"video"} required={true}/>
                                {uploadProgress > 0 && (
                                    <div className="upload-progress mt-3">
                                        <span>Uploading: {uploadProgress}%</span>
                                        <Progress percent={uploadProgress} status={uploadProgress === 100 ? "success" : "active"} />
                                        {uploadProgress === 100 && <div className={"flex gap-2"}><Spin/><p>Converting and saving the video...</p></div>}
                                    </div>
                                )}
                            </div>
                        </ModalBody>
                        <ModalFooter className={"flex flex-col justify-between mt-3"}>
                            <div className={"flex justify-between gap-2"}>
                                <Button icon={<CloseOutlined/>} className={"rounded-lg w-44"} onClick={() => {onClose();resetForm();}}>
                                    Cancel
                                </Button>
                                {elementId && (
                                    <Popconfirm
                                        title="Delete the video"
                                        description="Are you sure to delete it ?"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={() => {onClose(); resetForm();handleElementDelete()}}
                                    >
                                        <Button icon={<DeleteOutlined/>} type="primary" danger={true} className={"rounded-lg w-44 mx-auto"}>
                                            Delete
                                        </Button>
                                    </Popconfirm>
                                )}
                                {elementId ? (
                                        <Popconfirm
                                            title="Update the video"
                                            description="Are you sure to update it ?"
                                            okText="Yes"
                                            cancelText="No"
                                            onConfirm={handleElementSubmit}
                                        >
                                            <Button
                                                icon={<EditOutlined />}
                                                type="primary"
                                                className="rounded-lg w-44"
                                            >
                                                Update
                                            </Button>
                                        </Popconfirm>
                                    ) :
                                    <Popconfirm
                                        title="Create the video"
                                        description="Are you sure to create it ?"
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={handleElementSubmit}
                                    >
                                        <Button
                                            icon={<PlusOutlined />}
                                            type="primary"
                                            className="rounded-lg w-44"
                                        >
                                            Create
                                        </Button>
                                    </Popconfirm>
                                }
                            </div>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </div>
    )
};

export default VideoUpload;
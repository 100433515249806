import React, { useEffect, useState } from 'react';
import '../App.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import {Progress, Skeleton, Badge, Button} from "antd";
import MovieCard from "../components/MovieCard";
import HorizontalScroll from "../components/HorizontalScroll";
import VideoPlayer from "../components/VideoPlayer";
import {useDisclosure} from "@nextui-org/react";
import VideoUpload from "../components/VideoUpload";
import { scroller } from 'react-scroll';
import {useWindowDimensions} from "../contexts/WindowDimensionsContext";
import { useTheme } from '../contexts/ThemeContext';

const Backdrop = styled.div`
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${props => props.backdrop});
    background-size: cover;
    background-position: center;
`;

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const MovieDetails = ({ backendApiUrl }) => {
    const { isDarkMode } = useTheme()
    const [movie, setMovie] = useState(null);
    const [similarMovies, setSimilarMovies] = useState(null);
    const [recommendationMovies, setRecommendationMovies] = useState(null);
    const [collection, setCollection] = useState(null);
    const [backdropUrl, setBackdropUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isPrivate, setIsPrivate] = useState(false);
    const query = useQuery();
    const movieId = query.get("id");
    const urlIsPrivate = query.get("private");
    const [videoUrl, setVideoUrl] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const windowDimensions = useWindowDimensions()

    const formatReleaseDate = (dateString, full = true) => {
        const optionFull = { year: "numeric", month: "long", day: "numeric" };
        const optionYear = { year: "numeric" };

        return new Date(dateString).toLocaleDateString(undefined, full ? optionFull : optionYear);
    };

    function convertMinutes(minutes) {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}h ${remainingMinutes}m`;
    }

    function getTrailerUrl(videos) {
        if (!Array.isArray(videos)) {
            console.error("Invalid videos data");
            return null;
        }

        const trailer = videos.find(video =>
            video.name.toLowerCase().includes("official trailer") && video.site === "YouTube"
        );

        return trailer ? `https://www.youtube.com/embed/${trailer.key}` : null;
    }

    function getOtherUrl(videos) {
        if (!Array.isArray(videos)) {
            console.error("Invalid videos data");
            return [];
        }

        return videos
            .filter(video =>
                !video.name.toLowerCase().includes("official trailer")
            )
            .map(video => `https://www.youtube.com/embed/${video.key}`);
    }

    useEffect(() => {
        setMovie(null)
        setSimilarMovies(null)
        setRecommendationMovies(null)
        setCollection(null)
        setBackdropUrl(null)
        setVideoUrl("")

        if (!movieId) {
            setLoading(false);
            return;
        }

        const getPrivateToggle = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/private/get`);
                if (response.status === 200 && response.data?.value === true) {
                    setIsPrivate(true);
                }
            } catch (err) {
                console.error('Error getting private toggle:', err);
            }
        };

        const getMovie = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/movie/details/${movieId}`);
                setMovie(response.data);
                setBackdropUrl(
                    response.data.backdrop_path
                        ? `https://image.tmdb.org/t/p/w500${response.data.backdrop_path}`
                        : "https://via.placeholder.com/500x281?text=No+Backdrop"
                );
            } catch (err) {
                console.error('Error fetching movie:', err);
            } finally {
                setLoading(false);
            }
        };

        getMovie();
        getPrivateToggle();
        setVideoUrl("")
    }, [movieId]);

    useEffect(() => {
        if (urlIsPrivate === "true") {
            setIsPrivate(true);
        }
    }, [urlIsPrivate]);

    useEffect(() => {
        if (!movie)
            return;

        const getCollection = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/movie/collection/${movie.belongs_to_collection.id}`);
                setCollection(response.data);
            } catch (err) {
                console.error('Error fetching collection:', err);
            }
        };

        const getSimilar = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/movie/similar/${movie.id}`);
                setSimilarMovies(response.data);
            } catch (err) {
                console.error('Error fetching similar:', err);
            }
        };

        const getRecommendation = async () => {
            try {
                const response = await axios.get(`${backendApiUrl}/movie/recommendation/${movie.id}`);
                setRecommendationMovies(response.data);
            } catch (err) {
                console.error('Error fetching recommendation:', err);
            }
        };

        if (movie.belongs_to_collection)
            getCollection();

        if (movie.streaming)
            setVideoUrl(`${backendApiUrl}${movie.streaming}`)

        getSimilar()
        getRecommendation()
    }, [movie]);

    if (loading) {
        return (
            <div className={"flex flex-col"}>
                <Skeleton.Node
                    active={true}
                    style={{
                        width: "100%",
                        height: 610,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 1200,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 500,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 500,
                    }}
                />
            </div>
        );
    }

    return (
        movie ? <div>
            <Backdrop backdrop={`https://image.tmdb.org/t/p/w500${movie.backdrop_path}`}>
                <div className={"py-14 px-8 flex-wrap flex gap-10 justify-center"}>
                    <Badge.Ribbon text={"For Adult"} color="pink" placement={"start"} className={`mt-1 ${movie.adult ? "" : "hidden"}`}>
                        <Badge.Ribbon text="Available" color="green" className={`mt-1 ${movie.streaming || videoUrl ? "" : "hidden"}`}>
                            <img
                                src={
                                    movie.poster_path
                                        ? `https://image.tmdb.org/t/p/w500${movie.poster_path}`
                                        : "https://via.placeholder.com/130x195?text=No+Image"
                                }
                                alt={movie.title}
                                className={"h-[500px] rounded"}
                            />
                        </Badge.Ribbon>
                    </Badge.Ribbon>
                    <div className={"text-white relative max-w-4xl flex flex-col justify-center"}>
                        <div className={`flex ${!windowDimensions.wWCheck(700) && "flex-col"}`}>
                            <p className={`${windowDimensions.wWCheck(700) ? "text-6xl" : "text-3xl"} font-bold`}>{movie.title}</p>
                            <p className={`${windowDimensions.wWCheck(700) ? "text-3xl" : "text-xl"} text-gray-300 ${windowDimensions.wWCheck(700) && "ml-4"} font-light my-auto pt-2`}>{"(" + formatReleaseDate(movie.release_date, false) + ")"}</p>
                        </div>
                        <div className={"flex gap-6 flex-wrap mt-4"}>
                            <div className={"flex items-center"}>
                                <Progress percent={movie.vote_average * 10 >= 100 ? 99 : (movie.vote_average * 10).toFixed(2)} strokeColor={{'0%': '#FF4D4F', '40%': '#FFB01B', '60%': '#53C31B'}} showInfo={false} trailColor={"gray"} className={"w-40"}/>
                                <p className={`text-gray-300 ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} font-light ml-3 my-auto`}>{(movie.vote_average * 10).toFixed(0)}%</p>
                            </div>
                            <Button className={`${isPrivate ? (!movie.streaming && !videoUrl && urlIsPrivate !== "true") ? "hidden" : "" : "hidden"}`} onClick={() => movie.streaming || videoUrl ? scroller.scrollTo("streaming", {duration: 800, smooth: true, offset: -15,}) : onOpen()}>{movie.streaming || videoUrl ? "WATCH IT NOW" : "UPLOAD THE VIDEO"}</Button>
                        </div>
                        <p className={`text-gray-300 ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} font-light mt-12`}>{movie.tagline}</p>
                        <p className={`${windowDimensions.wWCheck(700) ? "text-4xl" : "text-2xl"} font-bold mt-3`}>Synopsis</p>
                        <p className={`mt-2 ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"}`}>{movie.overview}</p>
                        <p className={`text-gray-300 ${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"} font-light mt-12`}>Duration: {convertMinutes(movie.runtime)}</p>
                        <p className={`text-gray-300 ${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"} font-light mt-1`}>Genres: {movie.genres.map(genre => genre.name).join(', ')}</p>
                        <p className={`text-gray-300 ${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"} font-light mt-1`}>{movie.status + " | " + formatReleaseDate(movie.release_date)}</p>
                    </div>
                </div>
            </Backdrop>
            <div className={`w-full flex ${windowDimensions.wWCheck(900) ? "" : "flex-col"} justify-between my-14 gap-10 app`}>
                {!(!getTrailerUrl(movie.videos) && getOtherUrl(movie.videos).length === 0) && <div className={`${windowDimensions.wWCheck(900) ? "w-8/12" : ""}`}>
                    {getTrailerUrl(movie.videos) ? <div className={"mx-auto"}>
                        <p className={`w-full font-bold ${windowDimensions.wWCheck(700) ? "text-4xl" : "text-2xl"} ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>Trailer:</p>
                        <iframe
                            className={"w-full aspect-video rounded-xl mt-5"}
                            src={getTrailerUrl(movie.videos)}
                            title="Official Trailer"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </div> :
                    getOtherUrl(movie.videos).length !== 0 ? <div>
                        <p className={`w-full font-bold ${windowDimensions.wWCheck(700) ? "text-4xl" : "text-2xl"} mb-5 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>Other videos:</p>
                        <div className={"grid grid-cols-2 gap-3"}>
                            {getOtherUrl(movie.videos).slice(0, 4).map(video => (
                                <iframe
                                    className={"w-full aspect-video rounded-xl"}
                                    src={video}
                                    title="Other video"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                            ))}
                        </div>
                    </div> : null}
                    {collection && <div className={"mx-auto mt-20"}>
                        <p className={`w-full font-bold ${windowDimensions.wWCheck(700) ? "text-4xl" : "text-2xl"} mb-5 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>{collection.name}:</p>
                        <p className={`w-full ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mb-8`}>{collection.overview}</p>
                        <HorizontalScroll navigateButtons={windowDimensions.wWCheck(1000)}>
                            {collection.parts?.length > 0
                                ? collection.parts.map(movie => (
                                    <MovieCard key={movie.id} movie={movie} center={false}/>
                                ))
                                : Array(10).fill(null).map((_, skeletonIndex) => (
                                    <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                        <Skeleton.Node
                                            active={true}
                                            style={{
                                                width: 250,
                                                height: 375,
                                            }}
                                            className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                        />
                                        <Skeleton.Node
                                            active={true}
                                            style={{
                                                marginTop: "10px",
                                                marginBottom: "22px",
                                                width: 250,
                                                height: 90,
                                            }}
                                            className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                        />
                                    </div>
                                ))}
                        </HorizontalScroll>
                    </div>}
                </div>}
                <div className={`${!getTrailerUrl(movie.videos) && getOtherUrl(movie.videos).length === 0 ? "w-full" : `${windowDimensions.wWCheck(900) ? "w-4/12" : ""}`} flex flex-col`}>
                    <div className={`${!getTrailerUrl(movie.videos) && getOtherUrl(movie.videos).length === 0 ? "w-full" : `${windowDimensions.wWCheck(900) ? "w-[550px] ml-auto" : ""}`}`}>
                        <p className={`w-full font-bold ${windowDimensions.wWCheck(700) ? "text-4xl" : "text-2xl"} mb-6 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>Informations:</p>
                        <div className={`${!getTrailerUrl(movie.videos) && getOtherUrl(movie.videos).length === 0 ? "flex w-full gap-10 items-start flex-wrap" : ""}`}>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} ${!getTrailerUrl(movie.videos) && getOtherUrl(movie.videos).length === 0 ? "mt-2" : ""}`}>Original Title:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>{movie.original_title}</p>
                            </div>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mt-2`}>Status:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>{movie.status}</p>
                            </div>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mt-2`}>Original Language:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>{movie.original_language}</p>
                            </div>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mt-2`}>Origin Country:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>{movie.origin_country}</p>
                            </div>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mt-2`}>Adult:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>{movie.adult ? 'Yes' : 'No'}</p>
                            </div>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mt-2`}>Homepage:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>{movie.homepage}</p>
                            </div>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mt-2`}>Budget:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>${movie.budget.toLocaleString()}</p>
                            </div>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mt-2`}>Revenue:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>${movie.revenue.toLocaleString()}</p>
                            </div>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mt-2`}>Spoken Languages:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>{movie.spoken_languages.map(language => language.name).join(', ')}</p>

                            </div>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mt-2`}>Production Countries:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>{movie.production_countries.map(country => country.name).join(', ')}</p>
                            </div>
                            <div>
                                <p className={`font-bold ${windowDimensions.wWCheck(700) ? "text-lg" : "text-md"} mt-2`}>Production Companies:</p>
                                <p className={`${windowDimensions.wWCheck(700) ? "text-base" : "text-sm"}`}>{movie.production_companies.map(company => company.name).join(', ')}</p>
                            </div>
                        </div>
                    </div>
                    {getOtherUrl(movie.videos).length !== 0 && getTrailerUrl(movie.videos) !== null && <div className={`${windowDimensions.wWCheck(900) ? "max-w-[550px] ml-auto" : ""} mt-20 w-full`}>
                        <p className={`w-full font-bold ${windowDimensions.wWCheck(700) ? "text-4xl" : "text-2xl"} mb-5 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>Other videos:</p>
                        <div className={"grid grid-cols-1 gap-5"}>
                            {getOtherUrl(movie.videos).slice(0, 2).map(video => (
                                <iframe
                                    className={"w-full aspect-video rounded-xl"}
                                    src={video}
                                    title="Other video"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen>
                                </iframe>
                            ))}
                        </div>
                    </div>}
                </div>
            </div>
            {videoUrl &&
                <div id={"streaming"} className={`${isPrivate ? "" : "hidden"} mx-auto mt-20 app`}>
                    <p className={`w-full font-bold ${windowDimensions.wWCheck(700) ? "text-4xl" : "text-2xl"} mb-5 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>Streaming:</p>
                    <VideoPlayer videoUrl={videoUrl} />
                </div>}
            {similarMovies && <div className={"mx-auto mt-20 app"}>
                <p className={`w-full font-bold ${windowDimensions.wWCheck(700) ? "text-4xl" : "text-2xl"} mb-5 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>Similar Movies:</p>
                <HorizontalScroll navigateButtons={windowDimensions.wWCheck(1000)}>
                    {similarMovies?.length > 0
                        ? similarMovies.map(movie => (
                            <MovieCard key={movie.id} movie={movie}/>
                        ))
                        : Array(10).fill(null).map((_, skeletonIndex) => (
                            <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                <Skeleton.Node
                                    active={true}
                                    style={{
                                        width: 250,
                                        height: 375,
                                    }}
                                    className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                />
                                <Skeleton.Node
                                    active={true}
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "22px",
                                        width: 250,
                                        height: 90,
                                    }}
                                    className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                />
                            </div>
                        ))}
                </HorizontalScroll>
            </div>}
            {recommendationMovies && <div className={"mx-auto mt-20 app"}>
                <p className={`w-full font-bold ${windowDimensions.wWCheck(700) ? "text-4xl" : "text-2xl"} mb-5 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>Recommended Movies:</p>
                <HorizontalScroll navigateButtons={windowDimensions.wWCheck(1000)}>
                    {recommendationMovies?.length > 0
                        ? recommendationMovies.map(movie => (
                            <MovieCard key={movie.id} movie={movie}/>
                        ))
                        : Array(10).fill(null).map((_, skeletonIndex) => (
                            <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                <Skeleton.Node
                                    active={true}
                                    style={{
                                        width: 250,
                                        height: 375,
                                    }}
                                    className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                />
                                <Skeleton.Node
                                    active={true}
                                    style={{
                                        marginTop: "10px",
                                        marginBottom: "22px",
                                        width: 250,
                                        height: 90,
                                    }}
                                    className={`${windowDimensions.wWCheck(700) ? "" : "max-w-[140px] max-h-[230px]"}`}
                                />
                            </div>
                        ))}
                </HorizontalScroll>
            </div>}
            {isOpen &&<VideoUpload backendApiUrl={backendApiUrl} setVideoUrl={setVideoUrl} onClose={() => onClose()} isOpen={isOpen} uploadElementId={movie.id} type={"movie"}/>}
        </div>
            : <div className={"flex flex-col"}>
                <Skeleton.Node
                    active={true}
                    style={{
                        width: "100%",
                        height: 610,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 1200,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 500,
                    }}
                />
                <Skeleton.Node
                    active={true}
                    style={{
                        marginTop: "60px",
                        marginBottom: "22px",
                        width: "100%",
                        height: 500,
                    }}
                />
            </div>
    );
};

export default MovieDetails;

import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import {Link, animateScroll} from 'react-scroll';
import * as React from 'react';
import {useContext, useState} from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import {MoonOutlined, SunOutlined} from "@ant-design/icons"
import {Button} from "antd";
import { ThemeContext } from '../contexts/ThemeContext';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar({backendApiUrl}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const { isDarkMode, toggleTheme } = useContext(ThemeContext);
    const [navigation, setNavigation] = useState([
        {name: 'Home', to: "/", current: false, offset: -68},
        {name: 'Movies', to: "/movies", current: false, offset: -68},
        {name: 'Series', to: "/series", current: false, offset: -68},
    ]);

    const [navigationRight, setNavigationRight] = useState([
        {name: 'Now available', to: "/available", current: false, offset: -68},
    ]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const navigate = useNavigate();

    return (
        <Disclosure as="nav" className="header bg-white dark:bg-darkModeBackgroundColor dark:shadow-none w-full border-top shadow-xl">
            {({open}) => (
                <>
                    <div className="mx-8 md:mx-14 lg:mx-20 xl:mx-28 2xl:mx-32">
                        <div className="relative flex h-16 items-center justify-between">
                            <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                                <DisclosureButton
                                    className="relative inline-flex items-center justify-center rounded-md p-2 btprimary hover:bprimary dark:hover:bred hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                    <span className="absolute -inset-0.5"/>
                                    <span className="sr-only">Open main menu</span>
                                    {open ? (
                                        <XMarkIcon className="block h-6 w-6" aria-hidden="true"/>
                                    ) : (
                                        <Bars3Icon className="block h-6 w-6" aria-hidden="true"/>
                                    )}
                                </DisclosureButton>
                            </div>
                            <div className="flex flex-1 items-center justify-between lg:justify-start">
                                <div className="lg:hidden absolute inset-y-0 left-1/2 transform -translate-x-1/2 flex items-center">
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => {
                                            document.querySelector('button[aria-expanded="true"]')?.click();
                                            animateScroll.scrollToTop();
                                            navigate(process.env.REACT_APP_IS_DEVELOPMENT ? "/" : "/");
                                        }}
                                    >
                                        <img
                                            className="h-12 w-auto"
                                            src={isDarkMode ? "/logo-dark.png" : "/logo.png"}
                                            alt="Your Company"
                                        />
                                    </div>
                                </div>
                                <div className="lg:hidden ml-auto">
                                    <Button
                                        onClick={toggleTheme}
                                        style={{ color: isDarkMode ? "#b61e26" : "" }}
                                        className="ml-2"
                                        type="link"
                                        icon={isDarkMode ? <SunOutlined /> : <MoonOutlined />}
                                    />
                                </div>
                                <div
                                    className="hidden lg:flex items-center cursor-pointer"
                                    onClick={() => {
                                        document.querySelector('button[aria-expanded="true"]')?.click();
                                        navigate(process.env.REACT_APP_IS_DEVELOPMENT ? "/" : "/");
                                    }}
                                >
                                    <img
                                        className="h-12 w-auto"
                                        src={isDarkMode ? "/logo-dark.png" : "/logo.png"}
                                        alt="Your Company"
                                        onClick={() => {
                                            document.querySelector('button[aria-expanded="true"]')?.click();
                                            animateScroll.scrollToTop();
                                        }}
                                    />
                                </div>
                            <div className="hidden sm:ml-6 lg:block w-full">
                                    <div className={"flex"}>
                                        <div className="flex-center">
                                            {navigation.map((item) => {
                                                const isAnchorLink = item.to[0] !== '/';

                                                return isAnchorLink ? (
                                                    <ScrollLink
                                                        key={item.name}
                                                        to={item.to}
                                                        smooth={true}
                                                        duration={500}
                                                        offset={item.offset}
                                                        className={classNames(item.current ? 'bprimary text-white' : 'text-black dark:text-white hover:tprimary dark:hover:tred', 'rounded px-3 py-2.5 text-sm leading-none font-medium cursor-pointer mx-2 transition w-max')}
                                                    >
                                                        {item.name}
                                                    </ScrollLink>
                                                ) : (
                                                    <RouterLink
                                                        key={item.name}
                                                        to={item.to}
                                                        onClick={() => navigate(item.to)}
                                                        className={classNames(item.current ? 'bprimary text-white' : 'text-black dark:text-white hover:tprimary dark:hover:tred', 'rounded px-3 py-2.5 text-sm leading-none font-medium cursor-pointer mx-2 transition w-max')}
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                    </RouterLink>
                                                );
                                            })}
                                        </div>
                                        <div className="flex w-full justify-end">
                                            {navigationRight.map((item) => {
                                                const isAnchorLink = item.to[0] !== '/';

                                                return isAnchorLink ? (
                                                    <ScrollLink
                                                        key={item.name}
                                                        to={item.to}
                                                        smooth={true}
                                                        duration={500}
                                                        offset={item.offset}
                                                        className={classNames('bprimary text-white dark:text-white border rounded leading-none px-6 py-2 text-sm font-medium cursor-pointer mx-2 hover:bg-transparent hover:tprimary dark:hover:tred transition')}
                                                    >
                                                        {item.name}
                                                    </ScrollLink>
                                                ) : (
                                                    <RouterLink
                                                        key={item.name}
                                                        to={item.to}
                                                        onClick={() => navigate(item.to)}
                                                        className={classNames('bprimary text-white dark:text-white border rounded leading-none px-6 py-2 text-sm font-medium cursor-pointer mx-2 hover:bg-transparent hover:tprimary dark:hover:tred transition')}
                                                        aria-current={item.current ? 'page' : undefined}
                                                    >
                                                        {item.name}
                                                    </RouterLink>
                                                );
                                            })}
                                            <Button onClick={toggleTheme} style={{color: isDarkMode ? "#b61e26" : ""}} className={"ml-2"} type={"link"} icon={isDarkMode ? <SunOutlined /> : <MoonOutlined />}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DisclosurePanel className="lg:hidden">
                        <div className="pl-10 space-y-3 px-2 py-5 border-top shadow-xl border-bottom">
                            {navigation.concat(navigationRight).map((item) => (
                                <DisclosureButton key={item.name} as="div" className="w-full">
                                    {item.to[0] !== '/' ? (
                                        <ScrollLink
                                            key={item.name}
                                            to={item.to}
                                            smooth={true}
                                            duration={500}
                                            offset={item.offset}
                                            onClick={() => {
                                                document.querySelector('button[aria-expanded="true"]')?.click();
                                            }}
                                            className={classNames(item.current ? 'bprimary text-white' : 'text-black dark:text-white hover:tprimary dark:hover:tred', 'rounded px-3 py-4 leading-none font-medium cursor-pointer mx-2 transition w-max')}
                                        >
                                            {item.name}
                                        </ScrollLink>
                                    ) : (
                                        <RouterLink
                                            key={item.name}
                                            to={item.to}
                                            onClick={() => {document.querySelector('button[aria-expanded="true"]')?.click();navigate(item.to)}}
                                            className={classNames(item.current ? 'bprimary text-white' : 'text-black dark:text-white hover:tprimary dark:hover:tred', 'rounded px-3 py-4 leading-none font-medium cursor-pointer mx-2 transition w-max')}
                                            aria-current={item.current ? 'page' : undefined}
                                        >
                                            {item.name}
                                        </RouterLink>
                                    )}
                                </DisclosureButton>
                            ))}
                        </div>
                    </DisclosurePanel>
                </>
            )}
        </Disclosure>
    )
}
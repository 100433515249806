import React, {useEffect, useState} from "react";
import axios from "axios";
import MovieCard from "../components/MovieCard";
import {Divider, Empty, Pagination, Skeleton} from "antd";
import {scroller} from "react-scroll";
import SerieCard from "../components/SerieCard";
import Search from "antd/es/input/Search";
import { useTheme } from '../contexts/ThemeContext';
import {useWindowDimensions} from "../contexts/WindowDimensionsContext";

const Available = ({backendApiUrl}) => {
    const { isDarkMode } = useTheme()
    const [movies, setMovies] = useState([]);
    const [displayedMovies, setDisplayedMovies] = useState([]);
    const [loadingMovies, setLoadingMovies] = useState(0);
    const [series, setSeries] = useState([]);
    const [loadingSeries, setLoadingSeries] = useState(0);
    const [dislayedSeries, setDisplayedSeries] = useState([]);
    const [pageMovies, setPageMovies] = useState(0)
    const [pageSeries, setPageSeries] = useState(0)
    const [elementsStepMovies, setElementsStepMovies] = useState(10)
    const [elementsStepSeries, setElementsStepSeries] = useState(10)
    const windowDimensions = useWindowDimensions()

    useEffect(() => {
        const fetchMovies = async () => {
            setLoadingMovies(1)
            try {
                const response = await axios.get(`${backendApiUrl}/movies/available`);
                setMovies(response.data);
                setDisplayedMovies(response.data)
                setLoadingMovies(2)
            } catch (err) {
                console.error('Error fetching movies:', err);
                setLoadingMovies(3)
            }
        };

        const fetchSeries = async () => {
            setLoadingSeries(1)
            try {
                const response = await axios.get(`${backendApiUrl}/series/available`);
                setSeries(response.data);
                setDisplayedSeries(response.data)
                setLoadingSeries(2)
            } catch (err) {
                console.error('Error fetching series:', err);
                setLoadingSeries(3)
            }
        };

        fetchMovies();
        fetchSeries();
    }, []);

    useEffect(() => {
        if (pageMovies * elementsStepMovies >= movies.length) {
            setPageMovies(0)
        }
    }, [elementsStepMovies, displayedMovies.length]);

    useEffect(() => {
        if (pageSeries * elementsStepSeries >= series.length) {
            setPageSeries(0)
        }
    }, [elementsStepSeries, dislayedSeries.length]);

    const movieSearch = async (search) => {
        setPageMovies(0)
        if (search === "") {
            setDisplayedMovies(movies)
            return
        }
        setDisplayedMovies(movies.filter((movie) => movie.title.toLowerCase().includes(search.toLowerCase())))
    };

    const serieSearch = async (search) => {
        setPageSeries(0)
        if (search === "") {
            setDisplayedSeries(series)
            return
        }
        setDisplayedSeries(series.filter((serie) => serie.name.toLowerCase().includes(search.toLowerCase())))
    };

    return (
        <div className={"app"}>
            <Divider id={"movies"} style={{
                borderColor: isDarkMode ? "var(--my-red)" : "var(--my-blue)",
                color: isDarkMode ? "var(--my-red)" : "var(--my-blue",
                fontWeight: "bold",
                fontSize: windowDimensions.wWCheck(700) ? "30px" : "18px",
                marginTop: "70px",
                marginBottom: "50px",
                whiteSpace: "wrap"
            }}>AVAILABLE MOVIES</Divider>
            <div className={"w-full"}>
                <div className={"max-w-5xl mx-auto mb-20"}>
                    <p className={`text-left ${windowDimensions.wWCheck(700) ? "text-2xl" : "text-lg"} font-bold mb-3 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>Search in available movies</p>
                    <Search
                        placeholder="Search for a movie title"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={movieSearch}
                        disabled={loadingMovies === 3}
                    />
                </div>
                <div className={"w-full"}>
                    {loadingMovies !== 3 && <div id={"list"} className="list-small">
                        {displayedMovies?.length > 0
                            ? displayedMovies.slice(pageMovies * elementsStepMovies, (pageMovies + 1) * elementsStepMovies).map((movie) => (
                                <MovieCard key={movie.id} movie={movie} card={true} noMax={true}/>
                            ))
                            : Array(1).fill(null).map((_, skeletonIndex) => (
                                <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            width: 250,
                                            height: 375,
                                        }}
                                    />
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "22px",
                                            width: 250,
                                            height: 90,
                                        }}
                                    />
                                </div>
                            ))
                        }
                    </div>}
                    {loadingMovies === 3 && <div className={"w-full rounded-xl h-[350px] flex items-center justify-center mb-16"}><Empty description={"No movies available for now"} image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>}
                    {loadingMovies === 2 && <p className={"text-center mt-14 font-bold"}>{displayedMovies.length} available movies were found</p>}
                    <Pagination disabled={loadingMovies === 3} className={"mt-6"} current={pageMovies + 1} showQuickJumper
                                total={displayedMovies.length} align="center" onChange={(pageNumber, pageSize) => {
                        setPageMovies(pageNumber - 1);
                        setElementsStepMovies(pageSize);
                        scroller.scrollTo("list", {duration: 800, smooth: true, offset: -40})
                    }}/>
                </div>
            </div>
            <Divider id={"movies"} style={{
                borderColor: isDarkMode ? "var(--my-red)" : "var(--my-blue)",
                color: isDarkMode ? "var(--my-red)" : "var(--my-blue",
                fontWeight: "bold",
                fontSize: windowDimensions.wWCheck(700) ? "30px" : "18px",
                marginTop: "70px",
                marginBottom: "50px",
                whiteSpace: "wrap"
            }}>AVAILABLE SERIES</Divider>
            <div className={"w-full"}>
                <div className={"max-w-5xl mx-auto mb-20"}>
                    <p className={`text-left ${windowDimensions.wWCheck(700) ? "text-2xl" : "text-lg"} font-bold mb-3 ${isDarkMode ? "border-l-4 border-customRed pl-3" : "border-l-4 border-customBlue pl-3"}`}>Search in available series</p>
                    <Search
                        placeholder="Search for a serie title"
                        allowClear
                        enterButton="Search"
                        size="large"
                        onSearch={serieSearch}
                        disabled={loadingSeries === 3}
                    />
                </div>
                <div className={"w-full"}>
                    {loadingSeries !== 3 && <div id={"list"} className="list-small">
                        {dislayedSeries?.length > 0
                            ? dislayedSeries.slice(pageSeries * elementsStepSeries, (pageSeries + 1) * elementsStepSeries).map((serie) => (
                                <SerieCard key={serie.id} serie={serie} card={true} noMax={true}/>
                            ))
                            : Array(1).fill(null).map((_, skeletonIndex) => (
                                <div key={skeletonIndex} className={"flex flex-col mx-auto"}>
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            width: 250,
                                            height: 375,
                                        }}
                                    />
                                    <Skeleton.Node
                                        active={true}
                                        style={{
                                            marginTop: "10px",
                                            marginBottom: "22px",
                                            width: 250,
                                            height: 90,
                                        }}
                                    />
                                </div>
                            ))
                        }
                    </div>}
                    {loadingSeries === 3 && <div className={"w-full rounded-xl h-[350px] flex items-center justify-center mb-16"}><Empty description={"No series available for now"} image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>}
                    {loadingSeries === 2 && <p className={"text-center mt-14 font-bold"}>{dislayedSeries.length} available series were found</p>}
                    <Pagination disabled={loadingSeries === 3} className={"mt-6"} current={pageSeries + 1} showQuickJumper
                                total={dislayedSeries.length} align="center" onChange={(pageNumber, pageSize) => {
                        setPageSeries(pageNumber - 1);
                        setElementsStepSeries(pageSize);
                        scroller.scrollTo("list", {duration: 800, smooth: true, offset: -40})
                    }}/>
                </div>
            </div>
        </div>
    );
};

export default Available;